import React from 'react'

const Movie = ({movie}) => (
  <div className="movie">
    <img className="thumb" alt="movie cover" src={movie.multimedia.src} />
    <div>
      <h2>
        {movie.display_title}
      </h2>
      <p>{movie.summary_short}</p>
      <p>
        <a href={movie.link.url} target="_blank" rel="noopener noreferrer">Review</a>
        {' · '}
        <a href={`https://www.google.com/search?q=${movie.display_title}+movie+trailer`} target="_blank" rel="noopener noreferrer">Trailer</a>
        {' · '}
        <a href={`https://www.google.com/search?q="${movie.display_title}"+watch+movie`} target="_blank" rel="noopener noreferrer">Watch</a>
      </p>
      <div className="services">
        {/* {movie.services.map(service => (
          <a className="icon" href={service.url} target="_blank" rel="noopener noreferrer">{service.name}</a>
        ))} */}
      </div>
    </div>
  </div>
)

export default Movie
