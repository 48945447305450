import React from 'react'
import ReactDOM from 'react-dom'
import Home from './Home'
import Firebase from 'firebase/app'
import './styles.css'

const firebaseConfig = {
  apiKey: "AIzaSyBsEQCRtZ0Z6Fr-mhdZPPsPwDq0Nt_5L0I",
  projectId: "day-old-movies-live",
  measurementId: "G-EF1PQG4201"
}

Firebase.initializeApp(firebaseConfig)

ReactDOM.render(<Home />, document.getElementById('root'))