import React from 'react'
import Firebase from 'firebase/app'
import 'firebase/functions'

import Movie from './Movie'

class Home extends React.Component {
  state = {
    movies: [],
    loading: true,
  }
  
  componentDidMount() {
    const getMovieReviews = Firebase.functions().httpsCallable('getMovieReviews')
    getMovieReviews({})
      .then( movies => {
        this.setState({
          movies: movies.data,
          error: null,
          loading: false,
        })
      })
      .catch(error => {
        this.setState({
          movies: [],
          error,
          loading: false,
        })
      })
  }

  render() {
    const movies = this.state.movies.filter( movie => movie.critics_pick === 1)
    return (
      <div className="wrapper">

        <div>
          {/* <span className="emoji" role="img" aria-label="popcorn">🍿</span> */}
          <h1>Day-old movies</h1>
          <p className="subheading">Not as fresh, but just as tasty</p>

          <p>
            These movies were picked as a Critic's Choice by the New York Times about one year ago.
          </p>
          <p>
            Most of them are probably available to rent cheaply on streaming services by now.
          </p>

          <br />
          <br />
          <br />
        </div>

        {this.state.loading && 'loading...'}
        {this.state.error && `Error: ${this.state.error.message}`}
        {movies.map(movie => <Movie movie={movie} key={movie.display_name} /> )}

        <footer>
          © {(new Date()).getFullYear()}
          {' '}
          <a href="https://directedworks.com/" target="_blank" rel="noopener noreferrer">Directed Works LLC</a>
        </footer>

      </div>
    )
  }
}

export default Home
